import moment from 'moment'

import type { FormValue, OperatingDays, WeekDays } from '../types'

const getOperatingDays = (
  hoursOfOperations: FacilityOperation | undefined | null
): WeekDays[] | undefined => {
  if (!hoursOfOperations) {
    return undefined
  }

  const days: OperatingDays = {
    monday: hoursOfOperations.monday,
    tuesday: hoursOfOperations.tuesday,
    wednesday: hoursOfOperations.wednesday,
    thursday: hoursOfOperations.thursday,
    friday: hoursOfOperations.friday,
    saturday: hoursOfOperations.saturday,
    sunday: hoursOfOperations.sunday,
  }

  const operatingDays: WeekDays[] = []

  Object.entries(days).forEach((day) => {
    const [weekday, value] = day
    if (value) {
      operatingDays.push(weekday as keyof OperatingDays)
    }
  })

  return operatingDays
}

export default function facilityFormAdapter(
  facilityData: FacilityDetails
): FormValue {
  const opens = facilityData.hours_of_operations?.opens
  const closes = facilityData.hours_of_operations?.closes
  const notes = facilityData.notes ?? []

  return {
    company_name: facilityData.company_name || '',
    mode: facilityData.hours_of_operations?.mode || 'APPT',
    facility_address: {
      address: facilityData.address,
      city: facilityData.city,
      state: facilityData.state,
      zipcode: facilityData.zipcode,
      country: facilityData.country,
      lat: 0,
      lng: 0,
    },
    operating_days: getOperatingDays(facilityData.hours_of_operations),
    operating_time_from: opens
      ? moment(opens, 'hh:mm:ss')
      : moment().add(2, 'days').startOf('hour').set({ hour: 9 }),
    operating_time_to: closes
      ? moment(closes, 'hh:mm:ss')
      : moment().add(2, 'days').startOf('hour').set({ hour: 16 }),
    notes: notes.at(0)?.description || undefined,
  }
}
