import * as Yup from 'yup'

import { contactSchema } from 'components/FacilityDrawer/v2/Facility.schema'
import type { TransientFacilityV2 } from 'components/FacilityDrawer/v2/Facility.types'
import { validateTransientWithSchema } from 'utils/yup'

export const DEFAULT_ERROR_BANNER_MESSAGE =
  'Please fix the errors below in order to proceed.'
export const UNKNOWN_SERVER_ERROR_BANNER_MESSAGE =
  'Failed to update contacts, try again later.'

const schema = Yup.object().shape({
  contacts: Yup.array().of(contactSchema),
})

export function validate(
  facility: Pick<TransientFacilityV2, 'contacts'>
): [Pick<TransientFacilityV2, 'contacts'>, boolean] {
  return validateTransientWithSchema<Pick<TransientFacilityV2, 'contacts'>>(
    schema,
    facility
  )
}
