import { get } from 'lodash'
import { useCallback } from 'react'

import { triggerEvent } from '_shared_/custom-events/events'
import { useSettings } from 'contexts/settings'
import { mapFormValueToTransientFacilityV2 } from 'utils/adapters/facilityV1toV2'
import { mapFacilityDetailsV2ToV1 } from 'utils/adapters/facilityV2toV1'
import queryClient from 'utils/queryClient'

import type { FacilityDrawerProps } from './types'

function refetchShipperFacilities() {
  queryClient.refetchQueries({
    queryKey: ['retrieveShipperFacilities', { sort: 'company_name' }],
  })
}

function refetchFacilityDetails(facilityUUID: string) {
  queryClient.refetchQueries({
    queryKey: ['getFacilityDetails', facilityUUID],
  })
}

function refetchFacilityDetailsV2(facilityUUID: string) {
  queryClient.refetchQueries({
    queryKey: ['getFacilityDetailsV2', facilityUUID],
  })
}

/**
 * use this hook with the EventBasedFacilityDrawer and the EventBasedCreateFacilityDrawer.
 */
export function useFacilityDrawer(props?: {
  onFacilitySaved?: (facility: FacilityDetails) => void
}) {
  const onFacilitySaved = get(props, 'onFacilitySaved')

  const {
    values: [facilityDrawerV2Enabled],
  } = useSettings(['flags.ENABLE_FACILITY_DRAWER_V2'])
  const isV2FlagEnabled = Boolean(facilityDrawerV2Enabled)

  /**
   * Opens the `CreateFacilityDrawer`.
   * The `onFacilitySaved` callback provided here override the `onFacilitySaved` callback provided to the `useFacilityDrawer` hook (if any).
   */
  const openCreateFacilityFormV2 = useCallback(
    (options?: {
      onFacilitySaved?: (facility: FacilityDetails) => void
      initialValues?: FacilityDrawerProps['initialValues']
      formikProps?: FacilityDrawerProps['formikProps']
    }) => {
      const handleFacilitySaved = get(
        options,
        'onFacilitySaved',
        onFacilitySaved
      )

      triggerEvent('sg:open-create-facility-form', {
        initialValues: mapFormValueToTransientFacilityV2(
          options?.initialValues
        ),
        onFacilitySaved: (facility) => {
          refetchShipperFacilities()

          handleFacilitySaved?.(mapFacilityDetailsV2ToV1(facility))
        },
      })
    },
    [onFacilitySaved]
  )

  const openFacilityManagerDrawer = useCallback(
    (options: {
      facilityUUID: string
      onFacilitySaved?: (facility: FacilityDetails) => void
      initialValues?: FacilityDrawerProps['initialValues']
      formikProps?: FacilityDrawerProps['formikProps']
      initialView?: 'details' | 'edit-form'
    }) => {
      const { facilityUUID } = options

      const handleFacilitySaved = get(
        options,
        'onFacilitySaved',
        onFacilitySaved
      )

      triggerEvent('sg:open-facility-manager-v2', {
        ...options,
        facilityUUID,
        onFacilitySaved: (facility) => {
          refetchShipperFacilities()
          refetchFacilityDetailsV2(facilityUUID)

          handleFacilitySaved?.(mapFacilityDetailsV2ToV1(facility))
        },
      })
    },
    [onFacilitySaved]
  )

  /**
   * Opens the `FacilityManagerDrawer` in the edit-form view.
   * The `onFacilitySaved` callback provided here override the `onFacilitySaved` callback provided to the `useFacilityDrawer` hook (if any).
   */
  const openEditFacilityFormV2 = useCallback(
    (options: {
      facilityUUID: string
      onFacilitySaved?: (facility: FacilityDetails) => void
      initialValues?: FacilityDrawerProps['initialValues']
      formikProps?: FacilityDrawerProps['formikProps']
    }) => {
      openFacilityManagerDrawer({ initialView: 'edit-form', ...options })
    },
    [openFacilityManagerDrawer]
  )

  /**
   * Opens the `FacilityManagerDrawer` in the details view.
   * The `onFacilitySaved` callback provided here override the `onFacilitySaved` callback provided to the `useFacilityDrawer` hook (if any).
   */
  const openFacilityDetails = useCallback(
    (options: {
      facilityUUID: string
      onFacilitySaved?: (facility: FacilityDetails) => void
      initialValues?: FacilityDrawerProps['initialValues']
      formikProps?: FacilityDrawerProps['formikProps']
    }) => {
      openFacilityManagerDrawer({ initialView: 'details', ...options })
    },
    [openFacilityManagerDrawer]
  )

  /**
   * @deprecated
   * Opens the `FacilityDrawer`.
   * The `onFacilitySaved` callback provided here override the `onFacilitySaved` callback provided to the `useFacilityDrawer` hook (if any).
   * Please prefer to use the specific openCreateFacilityForm and openEditFacilityForm functions instead.
   */
  const openFacilityManagerV2 = useCallback(
    (options?: {
      facilityUUID?: string
      onFacilitySaved?: (facility: FacilityDetails) => void
      initialValues?: FacilityDrawerProps['initialValues']
      formikProps?: FacilityDrawerProps['formikProps']
    }) => {
      const facilityUUID = get(options, 'facilityUUID')

      if (facilityUUID) {
        openFacilityManagerDrawer({ facilityUUID, ...options })
      } else {
        openCreateFacilityFormV2(options)
      }
    },
    [openCreateFacilityFormV2, openFacilityManagerDrawer]
  )

  /**
   * Opens the `FacilityDrawer` without facilityUUID.
   * The `onFacilitySaved` callback provided here override the `onFacilitySaved` callback provided to the `useFacilityDrawer` hook (if any).
   */
  const openCreateFacilityForm = useCallback(
    (options?: {
      onFacilitySaved?: (facility: FacilityDetails) => void
      initialValues?: FacilityDrawerProps['initialValues']
      formikProps?: FacilityDrawerProps['formikProps']
    }) => {
      const handleFacilitySaved = get(
        options,
        'onFacilitySaved',
        onFacilitySaved
      )

      triggerEvent('sg:open-facility-manager', {
        ...options,
        callback: (facility: FacilityDetails) => {
          refetchShipperFacilities()

          handleFacilitySaved?.(facility)
        },
      })
    },
    [onFacilitySaved]
  )

  /**
   * Opens the `FacilityDrawer` with facilityUUID.
   * The `onFacilitySaved` callback provided here override the `onFacilitySaved` callback provided to the `useFacilityDrawer` hook (if any).
   */
  const openEditFacilityForm = useCallback(
    (options: {
      facilityUUID: string
      onFacilitySaved?: (facility: FacilityDetails) => void
      initialValues?: FacilityDrawerProps['initialValues']
      formikProps?: FacilityDrawerProps['formikProps']
    }) => {
      const { facilityUUID } = options

      const handleFacilitySaved = get(
        options,
        'onFacilitySaved',
        onFacilitySaved
      )

      triggerEvent('sg:open-facility-manager', {
        ...options,
        facilityUUID,
        callback: (facility: FacilityDetails) => {
          refetchShipperFacilities()
          // we only refetch the details if the user is editing the facility
          refetchFacilityDetails(facilityUUID)

          handleFacilitySaved?.(facility)
        },
      })
    },
    [onFacilitySaved]
  )

  /**
   * @deprecated
   * Opens the `FacilityDrawer`.
   * The `onFacilitySaved` callback provided here override the `onFacilitySaved` callback provided to the `useFacilityDrawer` hook (if any).
   * Please prefer to use the specific openCreateFacilityForm and openEditFacilityForm functions instead.
   */
  const openFacilityManager = useCallback(
    (options?: {
      facilityUUID?: string
      onFacilitySaved?: (facility: FacilityDetails) => void
      initialValues?: FacilityDrawerProps['initialValues']
      formikProps?: FacilityDrawerProps['formikProps']
    }) => {
      const facilityUUID = get(options, 'facilityUUID')

      if (facilityUUID) {
        openEditFacilityForm({ facilityUUID, ...options })
      } else {
        openCreateFacilityForm(options)
      }
    },
    [openCreateFacilityForm, openEditFacilityForm]
  )

  if (isV2FlagEnabled) {
    return {
      openCreateFacilityForm: openCreateFacilityFormV2,
      openEditFacilityForm: openEditFacilityFormV2,
      openFacilityDetails,
      openFacilityManager: openFacilityManagerV2,
    }
  }

  return {
    openCreateFacilityForm,
    openEditFacilityForm,
    openFacilityManager,
  }
}
