import {
  Banner,
  Card,
  Drawer,
  Icon,
  Layout,
  LogoLoader,
} from '@loadsmart/miranda-react'
import { useEffect } from 'react'

import { FacilityAccessorialsByMode } from 'components/FacilityDetails/FacilityAccessorialsByMode'
import { FacilityContactsTable } from 'components/FacilityDetails/FacilityContactsTable'
import { FacilityHoursOfOperationTable } from 'components/FacilityDetails/FacilityHoursOfOperationTable'
import { FacilityInstructions } from 'components/FacilityDetails/FacilityInstructions'
import { FacilitySummaryCard } from 'components/FacilityDetails/FacilitySummaryCard'
import { useFacilityDetailsV2 } from 'components/FacilityDetails/useFacilityDetailsV2'
import analytics, {
  AnalyticsEvent,
  AnalyticsEventTrigger,
} from 'utils/analytics'
import { scrollToTop } from 'utils/scroll'

import { CardSkeleton } from './CardSkeleton'

type FacilityDetailsBodyContentProps = {
  readonly facilityUUID: string
}

function FacilityDetailsBodyContent({
  facilityUUID,
}: FacilityDetailsBodyContentProps) {
  const {
    data: facility,
    isLoading,
    isError,
    refetch,
  } = useFacilityDetailsV2(facilityUUID)

  if (isLoading) {
    return (
      <>
        <Card>
          <Card.Body>
            <Layout.Group justify="center">
              <LogoLoader size="32px" aria-label="Loading facility details" />
            </Layout.Group>
          </Card.Body>
        </Card>
        <CardSkeleton title="Contacts" />
        <CardSkeleton title="Operating hours" />
        <CardSkeleton title="Instructions" />
        <CardSkeleton title="Accessorials" />
      </>
    )
  }

  if (isError || !facility) {
    return (
      <Banner variant="danger">
        <Banner.Title>Failed to load the facility details</Banner.Title>
        <Banner.Actions>
          <Banner.ActionPrimary onClick={() => refetch()}>
            Try again
          </Banner.ActionPrimary>
        </Banner.Actions>
      </Banner>
    )
  }

  return (
    <>
      <FacilitySummaryCard facility={facility} />

      <Card>
        <Card.Title>Contacts</Card.Title>
        <Card.Divider />
        <Card.Body>
          <FacilityContactsTable contacts={facility.contacts} />
        </Card.Body>
      </Card>

      <Card>
        <Card.Title>Operating hours</Card.Title>
        <Card.Divider />
        <Card.Body>
          <FacilityHoursOfOperationTable
            hours_of_operation={facility.hours_of_operation}
          />
        </Card.Body>
      </Card>

      <Card>
        <Card.Title>Instructions</Card.Title>
        <Card.Divider />
        <Card.Body>
          <FacilityInstructions notes={facility.notes} />
        </Card.Body>
      </Card>

      <Card>
        <Card.Title>Accessorials</Card.Title>
        <Card.Divider />
        <Card.Body>
          <FacilityAccessorialsByMode accessorials={facility.accessorials} />
        </Card.Body>
      </Card>
    </>
  )
}

type FacilityDetailsProps = {
  readonly facilityUUID: string
  readonly onEditFacility: () => void
}

export function FacilityDetails({
  facilityUUID,
  onEditFacility,
}: FacilityDetailsProps) {
  useEffect(() => {
    analytics.track(
      AnalyticsEvent.FacilityDrawerDetailsView,
      AnalyticsEventTrigger.view
    )
  }, [facilityUUID])

  return (
    <>
      <Drawer.Header role="heading">
        Facility
        <Drawer.Close aria-label="Close facility drawer" />
      </Drawer.Header>

      <Drawer.Body className="facility-details-drawer-body">
        <Layout.Stack gap="spacing-6">
          <FacilityDetailsBodyContent facilityUUID={facilityUUID} />
        </Layout.Stack>
      </Drawer.Body>

      <Drawer.Actions>
        <Layout.Group
          justify="space-between"
          align="center"
          style={{ width: '100%' }}
        >
          <Drawer.ActionTertiary
            leading={<Icon name="arrow-sort-up" size="16" />}
            onClick={() => {
              scrollToTop('.facility-details-drawer-body')
            }}
          >
            Back to top
          </Drawer.ActionTertiary>
          <Drawer.ActionTertiary
            leading={<Icon name="pencil" size="16" />}
            onClick={onEditFacility}
          >
            Edit
          </Drawer.ActionTertiary>
        </Layout.Group>
      </Drawer.Actions>
    </>
  )
}
